<template>
    <div class="mehr btm-menu">
        <breadcrumb style="z-index:1;" :background="true" :backButton="false" content="Weitere Inhalte"></breadcrumb>
        <ul class="mehr-menu">
            <router-link v-if="!userLoggedIn" class="mehr-menu-link__text seprator" :to="{ name: 'Login'}">
                <li class="mehr-menu-link seprator">
                    <div class="mehr-menu-link__icon">
                        <span class="material-icons-outlined" data-wg-notranslate="">person</span>
                    </div>
                    تسجيل الدخول            
                    <span class="material-icons-outlined show-ltr ml-auto" aria-hidden="true" data-wg-notranslate="">navigate_next</span>
                    <span class="material-icons-outlined show-rtl ml-auto" aria-hidden="true" data-wg-notranslate="">navigate_before</span>  
                </li>
            </router-link> 
            <router-link v-if="userLoggedIn" class="mehr-menu-link__text" :to="{ name: 'Profile'}">   
                <li class="mehr-menu-link seprator">
                  <div class="mehr-menu-link__icon">
                      <span class="material-icons-outlined" data-wg-notranslate="">person</span>
                  </div>ملفي الشخصي
                  <span class="material-icons-outlined show-ltr ml-auto" aria-hidden="true" data-wg-notranslate="">navigate_next</span>
                  <span class="material-icons-outlined show-rtl ml-auto" aria-hidden="true" data-wg-notranslate="">navigate_before</span>  
              </li>
          </router-link> 
          <router-link class="mehr-menu-link__text" :to="{ name: 'Datenschutz'}">  
              <li class="mehr-menu-link">
                <div class="mehr-menu-link__icon">
                    <span class="material-icons-outlined" data-wg-notranslate="">shield</span>
                </div>
                سياسة الخصوصية
                <span class="material-icons-outlined show-ltr ml-auto" aria-hidden="true" data-wg-notranslate="">navigate_next</span>
                <span class="material-icons-outlined show-rtl ml-auto" aria-hidden="true" data-wg-notranslate="">navigate_before</span>  
            </li>
        </router-link>
        <router-link class="mehr-menu-link__text" :to="{ name: 'Barrierefreiheitserklaerung'}">
            <li class="mehr-menu-link">
                <div class="mehr-menu-link__icon">
                    <span class="material-icons-outlined" data-wg-notranslate="">accessibility_new</span>
                </div>
                إمكانية الوصول
                <span class="material-icons-outlined show-ltr ml-auto" aria-hidden="true" data-wg-notranslate="">navigate_next</span>
                <span class="material-icons-outlined show-rtl ml-auto" aria-hidden="true" data-wg-notranslate="">navigate_before</span>  
            </li>
        </router-link>
        <router-link class="mehr-menu-link__text" :to="{ name: 'Impressum'}">
            <li class="mehr-menu-link seprator">
                <div class="mehr-menu-link__icon">
                    <span class="material-icons-outlined" data-wg-notranslate="">list</span>
                </div>
                بصمة
                <span class="material-icons-outlined show-ltr ml-auto" aria-hidden="true" data-wg-notranslate="">navigate_next</span>
                <span class="material-icons-outlined show-rtl ml-auto" aria-hidden="true" data-wg-notranslate="">navigate_before</span>  
            </li>
        </router-link>
        <a class="mehr-menu-link__text" href="https://bildungsangebote-kiel.de/" target="_blank">
            <li class="mehr-menu-link">
                <div class="mehr-menu-link__icon">
                    <span class="material-icons-outlined" data-wg-notranslate="">login</span>
                </div>
                تسجيل الدخول للمؤسسات
                <span class="material-icons-outlined show-ltr ml-auto" aria-hidden="true" data-wg-notranslate="">navigate_next</span>
                <span class="material-icons-outlined show-rtl ml-auto" aria-hidden="true" data-wg-notranslate="">navigate_before</span>  
            </li>
        </a>
        <a v-if="userLoggedIn" style="cursor: pointer" href="/" class="mehr-menu-link__text" @click.prevent="logOut">
            <li class="mehr-menu-link">
                <div class="mehr-menu-link__icon">
                    <span class="material-icons-outlined" data-wg-notranslate="">lock</span>
                </div>
                تسجيل الخروج               
                <span class="material-icons-outlined show-ltr ml-auto" aria-hidden="true" data-wg-notranslate="">navigate_next</span>
                <span class="material-icons-outlined show-rtl ml-auto" aria-hidden="true" data-wg-notranslate="">navigate_before</span>  
            </li>
        </a>
    </ul>
</div>
</template>

<script>
  import { showLoader, hideLoader } from '@/utils/helpers';


  export default {
    name: 'Mehr',
    components: {
      Breadcrumb: () => import('@/components/layout/Breadcrumb'),
  },
  computed: {
      userLoggedIn() {
        if(this.$store.getters.getToken){
          return true;
      }
      return false;
  },
  currentRoute(){
    return this.$route.name;
}
},
methods: {
  showLoader,
  hideLoader,
  logOut(){
    this.loader = this.showLoader(this.loader);
    this.$store.dispatch('logout');

    this.$notify({
      duration: 3500,
      title: "Erfolgreich abgemeldet!",
      text: "Du hast dich von dem Kiel.RefugeeCompass abgemeldet."
  });

    this.loader = this.hideLoader(this.loader);
    this.$router.push({name:'Home'});
},
},
}
</script>

<style lang="scss" scoped="">
    @import '@/scss/_variables.scss';

    .to-upper{
        text-transform:uppercase;
    }

    .software-by-design-icon{
        width:24px;
        height:24px;
    }

    .mehr {
        z-index: 0;
        background-color: $white-color;
        height: 100vh;
        display: flex;
        flex-direction: column;

        &-menu {
            display: flex;
            flex-direction: column;

            &-link {
                display: flex;
                flex-direction: row;
                height: 50px;
                align-items: center;
                padding: 0 15px;

                &.seprator {
                    border-bottom: 1px solid rgba($color: $dark-color, $alpha: 0.2);
                }

                &__icon{
                    width: 40px;
                    display: inline-flex;
                    align-items: center;
                }

                &__text {
                    text-transform: uppercase;
                    font-weight: 600;
                    font-size: 14px;
                    color: $dark-color;
                }
            }
        }
    }

    .mehr-menu-link__icon img {
        margin-left: 3px;
    }
</style>
